<template>
  <div style="width: 100%;">
    <v-row>
      <v-col v-if="isAllowed('location','c')" cols="4">
        <v-container fluid>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New Branch<span v-if="this.data.name">:</span> {{this.data.name}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="data.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          {
            text: "Name",
            sortable: true,
            value: "familyName"
          },
          {
            text: "Children IDs",
            value: "children"
          }
        ],
        editMode: false,
        data: {},
        allData: [],
        singular: "Location",
        singularLower: "location",
        plural: "Locations",
        pluralLower: "locations"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");
          this.data={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error

          this.allData = res.data.data

          let formattedLocations = [];

          this.allData.forEach(location => {
            let familyName = "";

            if(location.parent!==null){
              let parentPath = "";
              let currParent = location.parent;
              while(currParent!==null){
                let parent = this.allData.find(i => currParent===i.id);
                if(parent){
                  parentPath = parent.name+">"+parentPath;
                  currParent = parent.parent;
                  if(!parent.children) parent.children=[];
                  if(parent.id===location.parent) parent.children.push(location.id);
                  parent.children.sort();
                }
                else{
                  break;
                }
              }
              familyName=parentPath;
            }
            familyName+=location.name;

            location.familyName = familyName;

            formattedLocations.push(location);
          });

          formattedLocations.sort((a, b) => {
            return (a.familyName.toLowerCase() > b.familyName.toLowerCase()) ? 1 : -1
          });

          this.allData = formattedLocations;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
